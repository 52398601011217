import { RouteWithName } from '@/router';
import { leadMembershipArray, LeadMembership } from '@/services/leadDataService';
import { useUserStore } from '@/stores/userStore';
import { AsyncComponentLoader } from '@/router/useRouterHelpers';

export const routes: RouteWithName[] = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('./MainLayoutView.vue'),
    children: [
      {
        path: '',
        name: 'LandingPage',
        component: AsyncComponentLoader(() => import('./LandingPageView.vue')),
        meta: {
          requiresAuth: false,
          isFullPage: true,
        },
      },
      {
        path: 'welcome',
        name: 'WelcomePage',
        component: AsyncComponentLoader(() => import('./WelcomePageView.vue')),
        meta: {
          requiresAuth: true,
          isFullPage: true,
        },
      },
      {
        path: 'adminWelcome',
        name: 'AdminWelcomePage',
        component: AsyncComponentLoader(() => import('@/pages/AdminWelcomePage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'adminWelcome',
        },
      },
      {
        path: `viewAllLeads/:membership*`,
        name: `ViewAllLeads`,
        props: true,
        component: AsyncComponentLoader(() => import('./ViewAllLeadsView.vue')),
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
        beforeEnter: (to, from, next) => {
          if (leadMembershipArray.includes(to.params.membership[0] as LeadMembership)) {
            next();
            return;
          }
          next({ name: 'PageNotFound' });
        },
      },
      {
        path: 'dashboard',
        name: 'DashboardHome',
        component: AsyncComponentLoader(() => import('./DashboardHomeView.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'dashboard',
        },
      },
      {
        path: 'leadsPage',
        name: 'LeadsPage',
        component: AsyncComponentLoader(() => import('./LeadsPageView.vue')),
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
        props: route => ({ view: route.query.view }),
      },
      {
        path: 'leaderboard',
        name: 'LeaderBoardPage',
        component: AsyncComponentLoader(() => import('@/pages/LeaderBoardPage.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'manageSubscription/:checkoutSessionId?',
        name: 'ManageSubscription',
        component: AsyncComponentLoader(() => import('@/pages/ManageSubscription.vue')),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'pricingPage',
        name: 'PricingPage',
        component: () => import('@/pages/PricingPage.vue'),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: 'sagaMessaging',
        name: 'SagaMessagingPage',
        component: AsyncComponentLoader(() => import('./FastMessagingPageView.vue')),
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
      },
      {
        path: 'massMessaging',
        name: 'MassMessagingPage',
        component: AsyncComponentLoader(() => import('./MassMessagingPageView.vue')),
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
      },
      {
        path: 'fastMessaging',
        name: 'FastMessagingPage',
        redirect: '/sagaMessaging',
      },

      {
        path: 'linkMateStart',
        name: 'LinkMateOnboarding',
        component: AsyncComponentLoader(() => import('@/pages/linkMate/LinkMateOnboarding.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'followUp',
        redirect: '/followUp/TODAY',
      },
      {
        path: 'followUp/:filterSchedule',
        name: 'FollowUpPage',
        component: AsyncComponentLoader(() => import('./FollowUpPageView.vue')),
        props: true,
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
      },
      {
        path: 'noContacts',
        name: 'NoContactsPage',
        component: AsyncComponentLoader(() => import('@/views/shared/NoContactsPageView.vue')),
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
        props: route => ({ redirect: route.query.redirect }),
      },
      {
        path: 'helpCenter',
        name: 'HelpCenter',
        component: AsyncComponentLoader(() => import('@/pages/HelpCenter.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'library',
        name: 'LibraryPage',
        component: AsyncComponentLoader(() => import('@/pages/LibraryPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'library',
        },
      },
      {
        path: 'library/category/:categoryId',
        name: 'LibraryCategoryContentPage',
        component: AsyncComponentLoader(() => import('@/pages/LibraryCategoryContentPage.vue')),
        props: true,
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'library',
        },
      },
      {
        path: 'onboarding',
        name: 'OnBoardingPage',
        component: AsyncComponentLoader(() => import('./OnBoardingPageView.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'onboarding',
        },
      },
      {
        path: 'statistics',
        name: 'StatisticsPage',
        component: AsyncComponentLoader(() => import('./StatisticsPageView.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'statistics',
        },
      },
      {
        path: 'linkMate',
        name: 'LinkMatePage',
        component: AsyncComponentLoader(() => import('./LinkMatePageView.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'prospectingSettings',
        name: 'ProspectingToolsSettingsPage',
        component: AsyncComponentLoader(() => import('./ProspectingToolsSettingsPageView.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'analytics',
        name: 'AnalyticsPage',
        component: AsyncComponentLoader(() => import('./AnalyticsPageView.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'dmo',
        name: 'DMOPage',
        component: AsyncComponentLoader(() => import('./DMOPageView.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'dmo',
        },
      },
      {
        path: 'infoPage',
        name: 'InfoPageLinkPage',
        component: AsyncComponentLoader(() => import('./InfoPageLinkPageView.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'infoPages/business',
        name: 'BusinessInfoPageLinkPage',
        component: AsyncComponentLoader(() => import('./FrazerInfoPageLinkView.vue')),
        props: { type: 'business' },
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'businessInfoPage',
        },
      },
      {
        path: 'infoPages/product',
        name: 'ProductInfoPageLinkPage',
        component: AsyncComponentLoader(() => import('@/pages/FrazerInfoPageLinkPage.vue')),
        props: { type: 'product' },
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'productInfoPage',
        },
      },
      {
        path: 'leadMagnets',
        name: 'LeadMagnetLinkPage',
        component: AsyncComponentLoader(() => import('@/pages/LeadMagnetLinkPage.vue')),
        meta: {
          requiresAuth: true,
          hasAdminPage: true,
          resourceName: 'leadMagnets',
          changeBackgroundColor: true,
        },
      },
      {
        path: 'broadcast',
        name: 'BroadcastPage',
        component: AsyncComponentLoader(() => import('./BroadcastPageView.vue')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'email-lists/:currentEmailListPropId?',
        name: 'EmailListsPage',
        component: AsyncComponentLoader(() => import('./EmailListsPageView.vue')),
        props: true,
        meta: {
          requiresAuth: true,
          changeBackgroundColor: true,
        },
      },
      {
        path: 'supportCenter',
        name: 'SupportCenter',
        component: AsyncComponentLoader(() => import('@/pages/HelpCenter.vue')),
        meta: { requiresAuth: false },
      },
      {
        path: 'login',
        name: 'HostLogin',
        component: AsyncComponentLoader(() => import('@/pages/HostLogin.vue')),
        meta: { requiresAuth: false },
        beforeEnter: (to, from, next) => {
          const userStore = useUserStore();
          if (userStore.loggedInInfo?.jwt && userStore.loggedInInfo?.user?.tenant) {
            const currentName = to.name?.toString().replace(userStore.loggedInInfo.user.tenant.tenant, '');
            const nextPageName =
              currentName && currentName != `HostLogin`
                ? `${userStore.loggedInInfo.user.tenant.tenant}${String(currentName)}`
                : `${userStore.loggedInInfo.user.tenant.tenant}DashboardHome`;
            next({ name: nextPageName });
          }
          next();
        },
      },
      {
        path: 'checkout/:checkoutSessionId?',
        name: 'StripeCheckoutPage',
        component: AsyncComponentLoader(() => import('@/pages/StripeCheckoutPage.vue')),
        meta: { requiresAuth: false, isFullPage: true },
        props: true,
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: AsyncComponentLoader(() => import('@/pages/ForgotPassword.vue')),
        meta: { requiresAuth: false },
        props: route => ({ token: route.query.token }),
      },
      {
        path: 'register',
        name: 'RegisterPage',
        component: AsyncComponentLoader(() => import('@/pages/RegisterPage.vue')),
        meta: { requiresAuth: false },
        props: route => ({ showAllTenants: route.query.showAllTenants == 'true' }),
      },
      {
        path: 'userflow/help/:help',
        name: 'UserflowHelp',
        props: true,
        component: AsyncComponentLoader(() => import('@/pages/UserflowHelperPage.vue')),
        meta: { requiresAuth: true },
      },
      {
        path: 'ketones/:id/:lang?',
        name: 'ChatBotRedirect',
        props: true,
        component: AsyncComponentLoader(() => import('../../pages/ChatBotRedirect.vue')),
        meta: { requiresAuth: false },
      },
      {
        path: ':pathMatch(.*)*',
        name: 'PageNotFound',
        component: AsyncComponentLoader(() => import('@/pages/PageNotFound.vue')),
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: () => import('@/pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: ``,
        name: `LinkMateLeadPage`,
        component: AsyncComponentLoader(() => import('@/pages/linkMate/LinkMateLeadPage.vue')),
        props: true,
      },
      {
        path: 'resource/:resourceId',
        name: 'ExternalResourcePage',
        component: AsyncComponentLoader(() => import('@/pages/ExternalResourcePage.vue')),
        props: query => ({ ...query.params }),
      },
      {
        path: 'video/:videoId',
        name: 'ExternalVideoPage',
        component: AsyncComponentLoader(() => import('@/pages/ExternalVideoPage.vue')),
        props: query => ({ ...query.params }),
      },
      {
        path: 'info-page/info/:type',
        name: 'ExternalInfoPageWarm',
        component: AsyncComponentLoader(() => import('@/pages/linkMate/ExternalWarmInfoPage.vue')),
        props: route => ({
          ...route.params,
          type: route.params.type,
          subtype: 'warm',
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          clickOriginData: route.query.clickOriginData == 'linkMate',
        }),
      },
      {
        path: 'info-page/:type',
        name: 'ExternalInfoPageCold',
        component: AsyncComponentLoader(() => import('@/pages/linkMate/ExternalColdInfoPage.vue')),
        props: route => ({
          ...route.params,
          type: route.params.type,
          subtype: 'cold',
          unlocked: route.query.unlocked == 'unlocked',
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          clickOriginData: route.query.clickOriginData == 'linkMate',
        }),
      },
      // We cannot use redirect here because otherwise custom old info pages will not work
      // we need to have a different route name to avoid conflicts with the new lead magnet path /t while we merge routes
      {
        path: ':slug',
        name: 'ExternalLeadMagnetPageRedirect',
        component: AsyncComponentLoader(() => import('../../pages/linkMate/ExternalLeadMagnetPage.vue')),
        props: route => ({
          ...route.params,
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          success: route.query.success == 'success',
        }),
      },
      {
        path: 't/:slug',
        name: 'ExternalLeadMagnetPage',
        component: AsyncComponentLoader(() => import('../../pages/linkMate/ExternalLeadMagnetPage.vue')),
        props: route => ({
          ...route.params,
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          success: route.query.success == 'success',
        }),
      },
      {
        path: 'p/:slug',
        name: 'ExternalLeadMagnetPersonalizedPage',
        component: AsyncComponentLoader(() => import('../../pages/linkMate/ExternalLeadMagnetPage.vue')),
        props: route => ({
          ...route.params,
          isPersonal: true,
          isPreview: route.query.isPreview == 'true',
          language: route.query.language,
          success: route.query.success == 'success',
        }),
      },
    ],
  },
];
